export function Terms() {
    return (<div style={{padding: 50}}>
            <h3><strong>I. INTRODUCTION</strong></h3>
            <p><span>These Terms of Use and End User License Agreement (collectively, the &ldquo;Agreement&rdquo;) together with all the documents referred to in it constitute a legally binding agreement made between you as a natural person (&ldquo;you&rdquo;, &ldquo;your&rdquo; or &ldquo;user&rdquo;) and Lunapark Medya A.S (&ldquo;we,&rdquo; &ldquo;us&rdquo; or &ldquo;our&rdquo;), concerning your access to and use of &ldquo;Free Up&rdquo; software application for mobile devices (the &ldquo;App&rdquo;).</span></p>
            <p><span>The App&rsquo;s title may vary in countries other than the U.S. and is subject to change without specific notice.</span></p>
            <p><span>All the documents that relate to the App are hereby expressly incorporated herein by reference.</span></p>
            <p><span>Please read this Agreement carefully before you download, install or use the App.</span></p>
            <p><span>It is important that you read and understand this Agreement as by downloading, installing or using the App you indicate that you have read, understood, agreed and accepted the Agreement which takes effect on the date on which you download, install or use the App. By using the App, you agree to abide by this Agreement.</span></p>
            <p><span>If you do not agree with (do not accept) this Agreement, or if you do not agree at least with one of the provisions of this Agreement, you are not authorized to, and you may not access, download, install or use the App and you must promptly discontinue downloading, installing the App and remove (delete) the App from any mobile device in your possession or under your control.</span></p>
            <h3><strong>II. CHANGES TO THIS AGREEMENT</strong></h3>
            <p><span>We reserve the right, at our sole discretion, to make changes or modifications to this Agreement at any time and for any reason. We will keep you informed about any changes by updating this Agreement and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review this Agreement to stay informed of updates. You will be subject to, and will be deemed to be aware of and to have accepted, the changes in any revised Agreement by your continued use of the App after the date such revised Agreement is posted.</span></p>
            <h3><strong>III. RESTRICTIONS ON WHO CAN USE THE APP</strong></h3>
            <p><span>In order to download, install, access or use the App, you must be eighteen (18) years of age or older.</span></p>
            <p><span>All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the App, so if you are between the ages of thirteen (13) and seventeen (17) years and you wish to download, install, access or use the App, before doing so you must: (a) assure and confirm (if needed) that your parent or guardian have read and agree (get your parent or guardian&rsquo;s consent) to this Agreement prior to you using the App; (b) have the power to enter a binding contract with us and not be barred from doing so under any applicable laws.</span></p>
            <p><span>Parents and guardians must directly supervise any use of the App by minors.</span></p>
            <p><span>Any person under the age of thirteen (13) years is not permitted to download, install, access or use the App.</span></p>
            <p><span>You affirm that you are either more than eighteen (18) years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in this Agreement, and to abide by and comply with this Agreement.</span></p>
            <h3><strong>IV. GENERAL TERMS</strong></h3>
            <p><span>The App is a utility program designed to enhance your device experience.</span></p>
            <p><span>The App allows you to search and remove screenshots, similar pictures, similar Live Photos, similar burst photos; to search and remove duplicate contacts, to merge contacts, to backup contacts, to delete contacts in one tap (If you want to try more features, you can open the app to experience).</span></p>
            <h3><strong>V. PRIVACY POLICY</strong></h3>
            <p><span>Your privacy is very important to us. Accordingly, we have developed the Privacy Policy in order for you to understand how we process, use and store information including personal data. Access to the App and use of the Services are subject to the Privacy Policy. By accessing the App and by continuing to use the Services, you are deemed to have accepted the Privacy Policy, and in particular, you are deemed to have acknowledged the ways we process your information as well as appropriate legal grounds for processing described in the Privacy Policy. We reserve the right to amend the Privacy Policy from time to time. If you disagree with any part of the Privacy Policy, you must immediately stop using the App and Services. </span><span><br /></span><span><br /></span><strong>VI. PROHIBITED BEHAVIOUR</strong></p>
            <p><span>You agree not to use the App in any way that:</span></p>
            <p><span>is unlawful, illegal or unauthorized;</span></p>
            <p><span>is defamatory of any other person;</span></p>
            <p><span>is obscene or offensive;</span></p>
            <p><span>infringes any copyright, database right or trademark of any other person;advocates, promotes or assists any unlawful act such as (by way of example only) copyright infringement or computer misuse.</span></p>
            <p><span>You shall not make the App available to any third parties. In addition, you shall not modify, translate into other languages, reverse engineer, decompile, disassemble or otherwise create derivative works from the App or any documentation concerning the App.</span></p>
            <p><span>You shall not transfer, lend, rent, lease, distribute the App, or use it for providing services to a third party, or grant any rights to the App or any documentation concerning the App to a third party.</span></p>
            <p><span>Misuse of any trademarks or any other content displayed on the App is prohibited.</span></p>
            <p><span>You shall not copy and/or duplicate and/or distribute and/or publish and/or use any content in the App, directly or indirectly, by way of a violation of our Intellectual Property Rights.</span></p>
            <p><span>Moreover, you shall not make any attempts to use the App or part thereof for malicious intentions.</span></p>
            <p><span>Also, we are not responsible for the way you use the App.</span></p>
            <p><span>It is clarified that we may adopt, against a user who violated the present Agreement, any legal measures at our disposal pursuant to the applicable laws.</span></p>
            <h3><strong>VII. AVAILABILITY OF THE APP, SECURITY AND ACCURACY</strong></h3>
            <p><span>In order to use the App, you are required to have a compatible mobile phone or tablet, and internet access.</span></p>
            <p><span>The App is available for downloading and installing on handheld compatible mobile devices running Apple iOS Operating System 10.0 or later.</span></p>
            <p><span>We do not warrant that the App will be compatible with all hardware and software which you may use.</span></p>
            <p><span>We make no warranty that your access to the App will be uninterrupted, timely or error-free.</span></p>
            <p><span>Neither does Apple have an obligation whatsoever to furnish any maintenance and support services with respect to the App.</span></p>
            <p><span>You acknowledge the App is provided via the internet and mobile networks and so the quality and availability of the App may be affected by factors outside our reasonable control.</span></p>
            <p><span>The version of the App may be upgraded from time to time to add support for new functions and services.</span></p>
            <p><span>We may change or update the App and anything described in it without noticing you. If the need arises, we may suspend access to the App, or close it indefinitely.</span></p>
            <p><span>You also warrant that any information that you submit to us is true, accurate and complete, and you agree to keep it actual at all times.</span></p>
            <p><span>You can discontinue using our Services at any time by choosing the relevant option in your iTunes Account Settings. If you decide not to use the App for any reason you should uninstall the App.</span></p>
            <h3><strong>IX. THIRD PARTY WEBSITES AND RESOURCES</strong></h3>
            <p><span>The App may link you to other sites on the Internet and contracted third parties to provide you certain services. We have no control over and accept no responsibility for the content of any website or mobile application to which a link from the App exists (unless we are the provider of those linked websites or mobile applications). Such linked websites and mobile applications are provided &ldquo;as is&rdquo; for your convenience only with no warranty, express or implied, for the information provided within them.</span></p>
            <p><span>You acknowledge that you must comply with applicable third party terms of agreement when using the App. You are solely responsible for and bear all risks arising from your use of any third-party websites or resources.</span></p>
            <p><span>If you have any queries, concerns or complaints about such third party websites or mobile applications (including, but not limited to, queries, concerns or complaints relating to products, orders for products, faulty products and refunds) you must direct them to the operator of that third party website or mobile application.</span></p>
            <p><span>Please note that if you use private browser with ad blocking feature available within the App you should bear in mind that some websites have relevant anti ad blocking tools as well as applicable terms and policies that prohibit the use of ad blocking technologies by visitors. Hereby you acknowledge and agree that you are solely responsible for compliance with the terms of access, the terms of use, other applicable policies and regulations of such websites.</span></p>
            <h3><strong>X. DISCLAIMER OF WARRANTIES</strong></h3>
            <p><span>YOU AGREE THAT YOUR USE OF THE APP AND ITS SERVICES SHALL BE AT YOUR SOLE RISK. THE SERVICES AND ALL THE MATERIALS, INFORMATION, SOFTWARE, CONTENT INTEGRATED IN THE APP ARE PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo;. WE DO NOT MAKE ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, WITH REGARD TO THE MERCHANTABILITY, TECHNICAL COMPATIBILITY OR FITNESS FOR A PARTICULAR PURPOSE OF ANY SERVICE, PRODUCTS OR MATERIAL PROVIDED PURSUANT TO THIS AGREEMENT. WE DO NOT WARRANT THAT THE FUNCTIONS CONTAINED ON OR THROUGH THE SERVICES WILL BE AVAILABLE, UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR THE SERVERS THAT MAKE THE SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE DO NOT GUARANTEE 100% REMOVAL OF ALL DUPLICATE CONTACTS OR SIMILAR PICTURES OR SCREENSHOTS ON YOUR DEVICE.</span></p>
            <h3><strong>XI. TERMINATION</strong></h3>
            <p><span>We reserve the right to terminate this Agreement at any time at our sole discretion for any reason.</span></p>
            <p><span>Upon any termination, (a) the rights and licenses granted to you herein shall terminate; (b) you must cease all use of the App.</span></p>
            <h3><strong>XII. CONTACT INFORMATION</strong></h3>
            <p><span>We reserve the right to respond to user support requests. If you want to submit a support request or have any questions about this Agreement or the App, please contact us by email: </span><a href="mailto:support@getfreeup.ai"><span>support@getfreeup.ai</span></a></p>
            <p>&nbsp;</p>
            <p><span>Lunapark Medya A.S</span></p>
            <p><span>Last updated: July 2023</span><br /><br /></p>
        </div>
    )
}
